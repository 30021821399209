import React from 'react'
import styled from 'styled-components'
import { STYLES } from '../styles/constants'

const TagListContainer = styled.div`
  font-family: "Montserrat";
  font-weight: ${STYLES.FW_SEMIBOLD};
  font-size: 12px;
  color: ${STYLES.COLOR_PN_YELLOW};
  letter-spacing: 0.5px;
  line-height: 20px;
  text-transform: uppercase;
`;

class TagList extends React.Component {

  render() {
    const { tags } = this.props

    return (
      <TagListContainer className="tag-list">
        {tags.map((tag, i) => {
          if (tags.length === i + 1) {
            return (<span key={tag.id}>{tag.name}</span>)
          } else {
            return (<span key={tag.id}>{tag.name}, </span>)
          }
        })}
      </TagListContainer>
    )
  }
}

export default TagList